const flatpickr = require("flatpickr");
const monthSelectPlugin = require("flatpickr/dist/plugins/monthSelect");
const Portuguese = require("flatpickr/dist/l10n/pt.js").default.pt;

const langMap = {
    en: '',
    pt: Portuguese
};

/**
 * global locale
 * @type {string}
 */
let locale = getLang();

function getLang() {
	if (navigator.languages != undefined){
		return navigator.languages[0];
	} else {
		return navigator.language;
	}
}

try {
	if (locale !== 'en' && langMap[locale] !== undefined) {
		if (flatpickr && flatpickr.localize) {
			flatpickr.localize(langMap[locale]);
		}
	}
} catch (e) {
	// do nothing
}

window.monthSelectPlugin = monthSelectPlugin;
